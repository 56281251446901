.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.reversed {
  transform: rotate(180deg);
}

.reversed-text {
  font-size: 16px;
  color: grey;
  opacity: 0.5;
}

.hidden {
  display: none;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.6;
  }
}

@keyframes fadeInOut_oldman {

  0%,
  100% {
    opacity: 0;
  }



  50% {
    opacity: 1;
  }

}




.header-buttons {
  align-items: right;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  gap: 10px;
  padding: 20px 20px;
  z-index: 100;
}

.header-link {
  display: inline-flex;
  /* or other display properties to match your buttons */
  /* other styles to match your button's parent container */
}

.profile-name {
  font-size: 20px;
  color: hsl(272, 18%, 41%);

}

.header-button {
  /* Styling for individual buttons */
  background-color: hsl(272, 18%, 41%);
  border: 0px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 5px;
}

/*
.header-button-google {
  background-color: hsl(272, 18%, 41%);
  border: 0px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 5px;
}
*/
.header-button-google {
  background-color: #4285F4;
  /* Google blue */
  color: white;
  padding: 0;
  /* Remove padding */
  border: none;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  text-decoration: none;
  /* Adjust height as needed */
}

.my_account_button {
  /* Styling for individual buttons */
  padding: 5px 10px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  background-color: #341e4f;
  color: rgb(255, 255, 255);
  border: 2px solid rgb(70, 43, 94);
}

.my_account_button:hover {
  text-decoration: none;
  background-color: hsl(272, 18%, 41%);
  color: rgb(255, 255, 255);
  border: 3px solid hsl(272, 18%, 41%);
}

.header-button-google .google-logo {
  height: 100%;
  width: auto;
  /* Maintain aspect ratio */
  margin: 0 10px 0 0;
  /* Space after the logo */
  flex-shrink: 0;
  /* Prevent the logo from shrinking */
}


.google-logo {
  margin-left: 0px;
}

.header-button-google {
  background-color: #4285F4;
  /* Google blue */
  color: white;
  padding: 0px 15px 0px 0px;
  /* Zero padding on the left, 20px padding on the right */
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  /* Changed from inline-flex to flex */
  align-items: center;
  justify-content: flex-start;
  /* Align items to the start */
}


.header-button-google img {
  margin-right: 0px;
}


.header-button:hover {
  background-color: rgb(56, 150, 109);
}

.header-button-google:hover {
  background-color: rgb(56, 150, 109);
}

/* For Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* For IE, Edge, and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.tarot-star {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  animation: fadeInOut 5s infinite;
  width: 30%;
  height: auto;
}


.oldman_fade {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  animation: fadeInOut_oldman 10s infinite;
  width: 25%;
  height: auto;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.bottom-right-image {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30%;
  height: auto;
  z-index: 1;
}

.bottom-right-image2 {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 25%;
  height: auto;
  z-index: 1;
}

.loading-visual-female {
  animation: rotate360 5s linear infinite;
  max-width: 30%;
  /* Adjust as needed */
  opacity: 0.4;
  height: auto;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 133vh;
  z-index: 0;
}

.bottom-right-image-woman {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 25%;
  height: auto;
  z-index: 2;
}

.bottom-right-image-female {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 25%;
  height: auto;
  z-index: 2;
}

.AI-female-animate {
  position: fixed;
  bottom: 0;
  right: 0%;
  z-index: 2;
  animation: fadeInOut2 5s infinite;
  width: 25%;
  height: auto;
}

.AI-woman-animate {
  position: fixed;
  bottom: 0;
  right: 0%;
  z-index: 2;
  animation: fadeInOut2 5s infinite;
  width: 25%;
  height: auto;
}

@keyframes fadeInOut2 {

  0%,
  100% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.8;
    filter: box-shadow(0 0 20px rgb(7, 147, 35, 0.7));
  }
}

@keyframes fadeInOut3 {

  0%,
  100% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.9;
  }
}

@keyframes teleportIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.teleport-in {
  animation: teleportIn 1s ease-in-out;
}


@keyframes load {

  0%,
  100% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}

.loading-image {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 30%;
  height: auto;
  z-index: 2;
  animation: load 1s linear infinite;
}

.App-header {
  background-color: rgb(52, 30, 79);
  flex: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.tarot-cards-container {
  position: relative;
  z-index: 2;
}

.App-link {
  color: hsl(203.68, 68.47%, 43.53%)
}

.App-footer {
  text-align: center;
  flex: 1;
  margin-top: 0px;
  padding: 10px;
  font-size: 14px;
  background-color: rgb(52, 30, 79);
  color: #a49f9f;
}

.App-footer a {
  color: hsl(203.68, 68.47%, 43.53%);
  text-decoration: none;
  font-size: 16px;
  position: relative;
  z-index: 2;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  z-index: 2;
}

.container h2 {
  margin-bottom: 20px;
}

.user-input,
.user-select {
  margin: 0 5px;
  flex-grow: 1;
  min-width: 0;
  z-index: 2;

}

.prompt-input {
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  z-index: 2;

}

.result-image-wrapper {
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;
  border: 2px solid rgb(74, 40, 110);
  max-width: 600px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 5px;
  z-index: 2;
}

.result-image-wrapper h3 {
  text-align: center;
  margin-top: 0;
  z-index: 2;

}

.password-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 30, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;

}

.password-info {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5px;
  padding: 8px;
  border-radius: 10px;
  opacity: 1;
}

.password-info a {
  color: #248dfd;
  text-decoration: none;

}

.password-input {
  padding: 10px;
  font-size: 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  margin-bottom: 0px;
  box-shadow: 0 0 20px #000000, 0 0 5px #ffffff;

}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }
}

.loading-text {
  animation: blink 1.5s linear infinite;
  z-index: 2;
}

.loading-subtext {
  z-index: 2;
  font-size: 16px;
  opacity: 0.5;
}

.tarot-cards {
  display: flex;
  justify-content: center;
  gap: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tarot-cards div {
  text-align: center;
  width: 150px;
  margin: 0 auto;
}

.tarot-cards img {
  width: 120px;
  height: auto;

  display: block;
  margin: 0 auto;
}

.tarot-cards h5 {
  font-size: 0.7em;
  margin: 2px 0;
}

.card-animation {
  backface-visibility: hidden;
  opacity: 0;
  transform: rotateY(90deg);
  animation: flipIn 1s ease-in-out forwards;
}

@keyframes flipIn {
  from {
    transform: rotateY(90deg);
    opacity: 0;
  }

  to {
    transform: rotateY(0);
    opacity: 1;
  }
}

.card-delay-1 {
  animation-delay: 0.5s;
}

.card-delay-2 {
  animation-delay: 1s;
}

.card-delay-3 {
  animation-delay: 1.5s;
}

.button-design {
  margin-top: 0px;
  padding: 10px 15px;
  background-color: hsl(203.68, 68.47%, 43.53%);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  z-index: 3;

}

.button-design:hover {
  background: linear-gradient(270deg,
      hsl(276, 100%, 62%), hsl(203.68, 68.47%, 43.53%), rgb(196, 20, 137),
      hsl(0, 89%, 55%),
      hsl(32, 96%, 53%), rgb(16, 160, 26),
      hsl(276, 100%, 62%));
  background-size: 1000% 1000%;
  animation: gradientShift 2s infinite linear;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: -100% 50%;
  }
}




.button-design-refresh {
  margin-top: 0px;
  margin-left: 5px;
  padding: 10px 12px;
  background-color: hsl(0, 0%, 100%);
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  z-index: 3;
}

.button-design-refresh:hover {
  background-color: hsl(0, 0%, 0%);
  color: #ffffff;
}

@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 7px #b98145, 0 0 5px #3b203b;
  }

  50% {
    box-shadow: 0 0 15px #b98145, 0 0 15px #3b203b;
  }

  100% {
    box-shadow: 0 0 7px #b98145, 0 0 5px #3b203b;
  }
}

.button-design:disabled {
  background-color: #cccccc;
  /* Grayed out when disabled */
  cursor: default;
  z-index: 2;
}

.user-input,
.user-select,
#birth-date-time,
.prompt-input {
  padding: 5px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 0px;
  resize: none;
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
}

.user-input:focus,
#birth-date-time:focus,
.user-select:focus,
.prompt-input:focus {
  border-color: hsl(0, 0%, 0%);
  outline: none;
  box-shadow: 0 0 0 3px rgba(138, 153, 171, 0.4);
}

.user-select {
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}

.prompt-input {
  width: 99%;
  box-sizing: border-box;
  min-height: 80px;
}

.AI-woman-animate2 {
  position: fixed;
  bottom: 0;
  right: 0%;
  z-index: 2;
  animation: float 5s ease-in-out infinite;
  width: 25%;
  height: auto;
  opacity: 0.8;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

/* This will center the tarot deck container in the middle of the screen */
.tarot-deck-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  height: 150px;
  margin-bottom: 0px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.tarot-deck-container img {
  position: absolute;
  width: 30%;
  height: auto;
  z-index: 5;
}

.tarot1_stack1 {
  z-index: 10;
}

.tarot2_card1 {
  animation: cardFadeSlide 1.5s ease-in-out;
  --slide-direction: 100%;
  z-index: 20;
}

.tarot3_stack2 {
  z-index: 30;
}

.tarot4_card2 {
  animation: cardFadeSlide 1.5s ease-in-out;
  --slide-direction: -100%;
  z-index: 40;
}

.tarot5_stack3 {
  z-index: 50;
}

.tarot6_card3 {
  animation: cardFadeSlide 1.5s ease-in-out;
  --slide-direction: 100%;
  z-index: 60;
}

.tarot7_stack4 {
  z-index: 70;
}

.tarot8_stack5 {
  z-index: 80;
  animation: fadeInOut3 5s infinite;
}


@keyframes cardFadeSlide {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(var(--slide-direction));
  }
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  color: #000000;
  width: 25vw;
  height: 16vh;
  padding: 1vw;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2vw;
}

.popup-content button {
  padding: 0.5vw 0vw;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  width: 5vw;
  font-size: 1vw;
}

.popup-content button:hover {
  background-color: rgb(56, 150, 109);
}

.popup-content2 {
  background-color: white;
  color: #000000;
  width: 28vw;
  height: 12vh;
  padding: 1vw;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2vw;
}

.generated-text {
  max-width: 600px;
  margin: 20px auto;
  padding: 15px;
  border: 2px solid rgb(61, 36, 85);
  /*  border: 2px solid rgb(74, 40, 110); */
  border-radius: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;
  text-align: justify;
  background-color: rgb(46, 24, 70);
}

.generated-text h3 {
  text-align: center;
  margin-top: 0;
  z-index: 2;

}

/* Optional: Adjustments for smaller screens */
@media (max-width: 400px) {
  .popup-content {
    width: 40vw;
    height: 30vh;
    font-size: 3vw;
  }

  .popup-content button {
    font-size: 2.5vw;
    padding: 0vw 0vw;
  }
}

.feedback-link {
  position: absolute;
  font-size: 20px;
  top: 1.5%;
  left: 0px;
  padding: 10px;
  color: yellow;
  animation: fadeInOut3 5s infinite;
  /* Clear yellow text */
  cursor: pointer;
  z-index: 1000;
  transform: rotate(-10deg);
  /* Tilts the text. Adjust the angle as needed */

}

.user-input:disabled,
.user-select:disabled,
.prompt-input:disabled {
  color: #ffffff;
  background-color: rgb(46, 24, 70);
  border: 1px solid rgb(70, 43, 94);
  opacity: 1;
}

.user-input:disabled::placeholder,
.user-select:disabled::placeholder,
.prompt-input:disabled::placeholder {
  color: rgb(108, 79, 133);
  opacity: 1;
}